
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { from, Observable, throwError } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Storage } from '@ionic/storage-angular';

@Injectable()
export class HttpInterceptorCustom implements HttpInterceptor {
  constructor(
    private router: Router, private storage: Storage) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const resource: any = req.url;

    if (!resource.includes('assets') && !resource.includes('token')) {
      let resourceWithJson = resource;

      return from(this.storage.get("token"))
      .pipe(
        switchMap(token => {

          let header = req.headers
            .set('Accept', 'application/json')
            .set('Access-Control-Allow-Origin', '*');
            
            if (token) {
              header = header.set('Authorization', 'Bearer ' + token);
            }
              
          req = req.clone({
            headers: header,
            url: `${environment.apiUrl}` + resourceWithJson
          });

          return next.handle(req).pipe(
            map((event: HttpEvent<any>) => {
              if (event instanceof HttpResponse) {
                if (
                  (event.body &&
                    typeof event.body.success === 'boolean' &&
                    !event.body.success) ||
                  !event.ok
                ) {
                  console.error('ErrorEvent: ', event);
                  this.throwNewError(req);
                }
              }
              return event;
            }),
            catchError((error: HttpErrorResponse) => {
              if (error.status === 401) {
                this.router.navigate(['login']);
              }
              return throwError(error);
            })
          );
        })
      );
    }

    return next.handle(req);
  }

  public throwNewError(req: HttpRequest<any>): HttpErrorResponse {
    throw new HttpErrorResponse({
      error: 'Internal Error',
      headers: req.headers,
      status: 500,
      statusText: 'Warning',
      url: req.url
    });
  }
}
