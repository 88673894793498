import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService {

  constructor(public authenticationService: AuthService, private router: Router) { }

  canActivate(): boolean {
    let auth = this.authenticationService.isAuthenticated();
    if (!auth) {
      this.router.navigate(['login']);
    }
    return auth;
  }
}
