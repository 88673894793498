import { Injectable } from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {BehaviorSubject, Observable, throwError} from 'rxjs';
import {retry, catchError} from 'rxjs/operators';
import { Storage } from '@ionic/storage-angular';
import { Platform } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class RestService {

  constructor(private httpClient: HttpClient) {};

  getApi(url:string): Observable<any> {
    return this.httpClient.get<any>('/'+url)
      .pipe(
        retry(0),
        catchError(this.handleError)
      );
}

  postApi(url:string, data: any = null): Observable<any> {
      return this.httpClient.post<any>('/'+url, data)
        .pipe(
          retry(0),
          catchError(this.handleError)
        );
  }

  handleError(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Erro ocorreu no lado do client
      errorMessage = error.error.message;
    } else {
      // Erro ocorreu no lado do servidor
      errorMessage = `Código do erro: ${error.status}, ` + `menssagem: ${error.message}`;
    }
    return throwError(errorMessage);
  };
}
