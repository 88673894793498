import { Injectable } from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {BehaviorSubject, Observable, throwError} from 'rxjs';
import {retry, catchError} from 'rxjs/operators';
import { Storage } from '@ionic/storage-angular';
import { Platform } from '@ionic/angular';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  url = '/logar';

  authState = new BehaviorSubject(false);

  constructor(private httpClient: HttpClient, private storage: Storage, private platform: Platform, private router: Router) {
    this.platform.ready().then(async () => {
      await this.ifLoggedIn();
    });
  };

  // Headers
  httpOptions = {
    headers: new HttpHeaders({'Content-Type': 'application/json'})
  }

  async ifLoggedIn() {
    this.storage = await this.storage.create();
    let exist = await this.storage.get('token');
    if (exist) {
      this.authState.next(true);
    }
  }

  isAuthenticated() {
    return this.authState.value;
  }

  setAuthenticated(token) {
    this.storage.set('token', token);
    localStorage.setItem('token', token);
    this.authState.next(true);
  }

  exitAuthenticated() {
    this.storage.remove('token');
    localStorage.removeItem('token');
    this.authState.next(false);
    this.router.navigate(['login']);
  }

  postLogin(auth: any): Observable<any> {
      this.storage.remove('token');
      localStorage.removeItem('token');
      return this.httpClient.post<any>(this.url, auth)
  }

  postRegister(auth: any): Observable<any> {
    this.storage.remove('token');
    localStorage.removeItem('token');
    return this.httpClient.post<any>('/cadastrar', auth);
  }

  postEsqueci(auth: any): Observable<any> {
    this.storage.remove('token');
    localStorage.removeItem('token');
    return this.httpClient.post<any>('/esqueci', auth);
  }

  postSenha(auth: any): Observable<any> {
    return this.httpClient.post<any>('/senha', auth);
  }

  handleError(error: HttpErrorResponse) {
    let errorMessage = '';
    if (!error.error.success && error.error.data && error.error.data.error) {
      errorMessage = error.error.data.error;
    } else if (error.error instanceof ErrorEvent) {
      // Erro ocorreu no lado do client
      errorMessage = error.error.message;
    } else {
      // Erro ocorreu no lado do servidor
      errorMessage = error.message;
    }
    return throwError(errorMessage);
  };
}
